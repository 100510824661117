<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">关注列表</div>
      <div class="topRight">
        <img src="../../image/quanz/jhy.png" alt="" @click="goSeekFriend()" />
      </div>
    </div>
    <div class="searBox">
      <img src="../../image/quanz/s.png" alt="" />
      <input type="text" placeholder="请输入成员昵称" />
    </div>
    <div class="fsNum">
      <div>我的关注（{{ followMeList.length }}）人</div>
      <van-popover
        v-model="showPopover"
        trigger="click"
        :actions="actions"
        placement="bottom-end"
        @select="onSelect"
      >
        <template #reference>
          <div class="zjgz">
            最近关注 <img src="../../image/quanz/px.png" alt="" />
          </div>
        </template>
      </van-popover>
    </div>
    <div class="container" v-for="item in followMeList" :key="item.id">
      <div class="fansHear" @click="gotrends()">
        <img :src="item.followHeadImg" alt="">
      </div>
      <div class="nameBrief">
        <div>{{ item.notes }}</div>
        <div>{{ item.schoolName }}</div>
      </div>
      <div class="gzStyle" @click="showPopup(item)">
        <img src="../../image/quanz/ygz.png" alt="" />
      </div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <div class="popupDiv2" @click="lhEveent()">不再关注</div>
      <div class="line"></div>
      <div class="popupDiv1" @click="show = false">取消</div>
    </van-popup>
  </div>
</template>

<script>
import { Popover } from "vant";
import { followMe, myCollect, followCancel } from "../../api/circle";
import { Toast } from "vant";
export default {
  data() {
    return {
      show: false,
      showPopover: false,
      actions: [{ text: "最近关注" }, { text: "最早关注" }],
      followMeList: [],
      sort: "",
      cancel: {},
    };
  },
  mounted() {
    console.log(this.array);
    this.getfollowMe();
  },
  methods: {
    getfollowMe() {
      followMe({
        pageNum: 1,
        pageSize: 10,
        sort: this.sort,
      }).then((res) => {
        console.log(res, "关注");
        this.followMeList = res.data.data.items;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    onSelect(action) {
      // Toast(action.text);
      if (action.text === "最早关注") {
        this.sort = "asc";
      } else if (action.text === "最近关注") {
        this.sort = "desc";
      }
      this.getfollowMe();
    },
    showPopup(item) {
      // console.log(item);
      this.cancel = item;
      this.show = true;
    },
    // 取消关注
    lhEveent() {
      followCancel(this.cancel.followId).then((res) => {
        // console.log(res, "取消关注");
        if (res.data.code === 0) {
          this.getfollowMe();
        }
        this.show = false;
      });
    },
    // 跳转动态
    gotrends() {
      this.$router.push({ path: "/TrendIndex" });
    },
    // 查找好友
    goSeekFriend() {
      this.$router.push({ path: "/seekFriend" });
    },
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 216px;
}
.topRight img {
  display: flex;
  width: 172px;
  height: 56px;
  margin-left: 118px;
}
.searBox {
  width: 686px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 34px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  display: flex;
  align-items: center;
}
.searBox img {
  width: 32px;
  height: 32px;
  margin-left: 24px;
  margin-right: 12px;
}
.searBox input {
  width: 400px;
  border: none;
  background: #f6f8fa;
}
.fsNum {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 38px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
.zjgz {
  font-size: 24px;
  font-weight: 600;
  color: #97a4b4;
  display: flex;
  align-items: center;
}
.zjgz img {
  width: 24px;
  height: 24px;
  margin-right: 32px;
  margin-left: 18px;
}
.container {
  width: 686px;
  height: 144px;
  border-bottom: 1px solid #f6f8fa;
  margin-left: 32px;
  display: flex;
  align-items: center;
  /* padding-top: 32px;
    box-sizing: border-box; */
}
.fansHear img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.nameBrief {
  width: 470px;
  margin-left: 20px;
}
.nameBrief div:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}

.nameBrief div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
}
.gzStyle img {
  width: 120px;
  height: 56px;
}
.popupDiv,
.popupDiv1,
.popupDiv2 {
  width: 750px;
  height: 104px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 104px;
  border-bottom: 1px solid #f6f8fa;
}
.popupDiv1 {
  border: none;
}
.popupDiv2 {
  color: #fa2819;
}
.line {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
</style>
